import {
  FinanceCampaignMessage,
  ICampaignOffer,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'

export const useVehicleOffers = (
  vehicle: Ref<NewVehicle | UsedVehicle | null>
) => {
  // Available retailer offers
  const retailerOffers = computed(() => {
    const offers = vehicle.value?.Offers?.Combined?.RetailerOffers || []

    return offers.filter((offer) => isOfferAvailable(offer))
  })

  // Available campaign offers
  const campaignOffers = computed(() => {
    const offers = vehicle.value?.Offers?.Combined?.CampaignOffers || []

    return offers.filter((offer) => isOfferAvailable(offer))
  })

  // Available non monetary offers
  const nonMonetaryOffers = computed(() => {
    return [...retailerOffers.value, ...campaignOffers.value].filter(
      (offer) => offer?.OfferType !== 'Discount'
    )
  })

  // Available OLEV offers
  const olevOffers = computed(() => {
    const offers =
      vehicle?.value?.Offers?.Combined?.CampaignOLEVGrantOffers || []

    return offers.filter((offer) => isOfferAvailable(offer))
  })

  // Available monetary offers
  const monetaryOffers = computed(() => {
    return [
      ...retailerOffers.value,
      ...campaignOffers.value,
      ...olevOffers.value
    ].filter((offer) => offer?.OfferType === 'Discount')
  })

  // Finance Offers
  const financeOffers = computed(() => {
    const offers = vehicle.value?.Offers?.Combined?.FinanceOfferMessages || []

    return offers.filter(
      (offer) => isOfferAvailable(offer) && offer?.OfferType === 'Finance'
    )
  })

  // Campaign Messages
  const campaignMessages = computed(() => {
    return vehicle.value?.Offers?.Combined?.CampaignMessages
  })

  // Getters
  const hasMonetaryOffers = computed(() => monetaryOffers.value.length > 0)

  const hasNonMonetaryOffers = computed(
    () => nonMonetaryOffers.value.length > 0
  )

  const hasFinanceOffers = computed(() => financeOffers.value.length > 0)

  const hasTotalOffer = computed(() => !!vehicle.value?.TotalOfferValue)

  const totalOfferLabel = computed(
    () => `${formatGBP(vehicle.value?.TotalOfferValue)} off`
  )

  const hasOffers = computed(() => {
    return (
      hasMonetaryOffers.value ||
      hasNonMonetaryOffers.value ||
      hasFinanceOffers.value
    )
  })

  // Helper functions
  const isOfferAvailable = (offer: ICampaignOffer | FinanceCampaignMessage) =>
    offer?.Enabled && offer?.Pending === false

  return {
    retailerOffers,
    campaignOffers,
    campaignMessages,
    financeOffers,
    hasFinanceOffers,
    nonMonetaryOffers,
    olevOffers,
    monetaryOffers,
    hasOffers,
    hasMonetaryOffers,
    hasNonMonetaryOffers,
    hasTotalOffer,
    totalOfferLabel
  }
}
